
* {
    font-size: 16px;
    color: #222;
}
.main-container {
    position: relative;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px 10px 0 0;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.container {
    padding: 40px 40px 100px;
    .first-title {
        font-size: 24px;
        margin-bottom: 50px;
    }
    .line {
        margin-top: 20px;
    }
    .second-title {
        margin-top: 40px;
        font-size: 18px;
    }
    .red {
        color: #FE1E00;
    }
}
a {
    color: #5A50EF;
}
img {
    display: block;
    margin: 20px 0 0 50px;
    max-width: calc(100% - 50px);
}
